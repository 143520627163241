
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import DefaultLayout from '@/components/DefaultLayout.vue';
import { AdminService } from '../../services/admin-service';
import AddCustomerDialog from '@/components/AddCustomerDialog.vue';
const namespace: string = 'profile';
@Component({
  components: { DefaultLayout, AddCustomerDialog }
})
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
    AddCustomerDialog: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */

  @Action('login', { namespace })
  login: any;
  @Action('getImpersonatedCustomer', { namespace })
  getImpersonatedCustomer: any;

  @Getter('impersonatedUser', { namespace: 'profile' })
  impersonatedCustomer!: string;

  /* Watchers */

  @Watch('search')
  onSearchChanged(oldVal: any, val: any) {}
  /* Data */

  customers: any[] = [];
  isLoadingCustomers: boolean = false;
  isImpersonating: boolean = false;
  userName: string = '';
  search: string = '';

  /* Methods */

  async getAllCustomers() {
    this.isLoadingCustomers = true;
    const customers = await AdminService.getAllCustomers();
    customers.data.forEach((customer: any) => {
      this.customers.push({ ...customer, isVisible: true });
    });
    this.isLoadingCustomers = false;
  }
  async impersonate(customerId: string) {
    this.isImpersonating = true;
    const formData: any = new FormData();
    formData.set('userName', this.userName);
    formData.set('customerId', customerId);
    try {
      const response = await this.login(formData);
      const impersonatedCustomer = await this.getImpersonatedCustomer();
      this.isImpersonating = false;
      SetSnackBar('Impersonation successful');
      location.reload();
      return false;
    } catch (err) {
      SetSnackBar('Could not impersonate');
      this.isImpersonating = false;
    }
  }
  editImpersonatedCustomer() {
    const customer = this.customers.find(c => c.Name == this.impersonatedCustomer);
    this.edit(customer.PublicId);
  }
  edit(id: string) {
    this.$router.push(`/editcustomer/${id}`);
  }
  addCustomer() {
    this.$refs.AddCustomerDialog.openDialog();
  }
  /* Computed */
  get customersInSearch() {
    if (!this.search) {
      return this.customers;
    }
    return this.customers.filter(c =>
      c.Name.toLowerCase().includes(this.search.toLowerCase())
    );
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  mounted() {
    this.getAllCustomers();
    const user = localStorage.getItem('user');
    if (user) {
      this.userName = JSON.parse(user).userName;
    }
  }
  created() {}
  destroyed() {}
}
